var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-virtual-table',{attrs:{"grid":_vm.grid,"columns":_vm.columns,"items":_vm.items,"selection":"multiple","with-action-btns":"","selected":_vm.selected},on:{"update:selected":_vm.onSelection},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('q-space'),_c('q-btn',{class:_vm.btnClass(true),attrs:{"icon":"mdi-pencil-outline","color":"primary","label":"Create merchant","to":{ name: 'MerchantCreate' }}})]},proxy:true},{key:"body-cell-payout-callback",fn:function(ref){
var props = ref.props;
return [_c('q-td',{staticClass:"cursor-pointer",attrs:{"props":props},on:{"click":function($event){return _vm.copyToClipboard(props.value)}}},[_vm._v(" "+_vm._s(props.value)+" "),_c('q-tooltip',{attrs:{"offset":[10, 1]}},[_vm._v(" Click to copy to clipboard ")])],1)]}},{key:"body-cell-refill-callback",fn:function(ref){
var props = ref.props;
return [_c('q-td',{staticClass:"cursor-pointer",attrs:{"props":props},on:{"click":function($event){return _vm.copyToClipboard(props.value)}}},[_vm._v(" "+_vm._s(props.value)+" "),_c('q-tooltip',{attrs:{"offset":[10, 1]}},[_vm._v(" Click to copy to clipboard ")])],1)]}},{key:"body-cell-action-btns",fn:function(ref){
var props = ref.props;
return [_c('q-td',{attrs:{"props":props}},[_c('d-row-actions',{attrs:{"item":props,"page-name":"MerchantEdit"},on:{"on-remove":_vm.removeFn}})],1)]}},{key:"grid-item",fn:function(ref){
var props = ref.props;
return [_c('d-grid-card',{attrs:{"item":props},on:{"on-remove":_vm.removeFn}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }