<template>
  <d-virtual-table
    :grid="grid"
    :columns="columns"
    :items="items"
    selection="multiple"
    with-action-btns
    :selected="selected"
    @update:selected="onSelection"
  >
    <template v-slot:top>
      <q-space />
      <q-btn
        :class="btnClass(true)"
        icon="mdi-pencil-outline"
        color="primary"
        label="Create merchant"
        :to="{ name: 'MerchantCreate' }"
      />
    </template>
    <template v-slot:body-cell-payout-callback="{ props }">
      <q-td
        :props="props"
        @click="copyToClipboard(props.value)"
        class="cursor-pointer"
      >
        {{ props.value }}
        <q-tooltip :offset="[10, 1]"> Click to copy to clipboard </q-tooltip>
      </q-td>
    </template>
    <template v-slot:body-cell-refill-callback="{ props }">
      <q-td
        :props="props"
        @click="copyToClipboard(props.value)"
        class="cursor-pointer"
      >
        {{ props.value }}
        <q-tooltip :offset="[10, 1]"> Click to copy to clipboard </q-tooltip>
      </q-td>
    </template>
    <template v-slot:body-cell-action-btns="{ props }">
      <q-td :props="props">
        <d-row-actions
          :item="props"
          page-name="MerchantEdit"
          @on-remove="removeFn"
        />
      </q-td>
    </template>
    <template v-slot:grid-item="{ props }">
      <d-grid-card :item="props" @on-remove="removeFn" />
    </template>
  </d-virtual-table>
</template>

<script>
import { tableColumns } from './config';
import { DGridCard } from './features';
import { DRowActions } from '@/features/row-actions';
import { merchantController } from '@/shared/api';
import withActionTableClasses from '@/shared/mixins/withActionTableClasses';
import DVirtualTable from '@/shared/ui/virtual-table';
import { copyToBuffer as copyToClipboard } from '@/shared/utils';
import { mapGetters } from 'vuex';

export default {
  components: { DRowActions, DVirtualTable, DGridCard },
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => undefined,
    },
    removeFn: {
      type: Function,
      default: () => undefined,
    },
  },
  mixins: [withActionTableClasses],
  data: () => ({
    merchantController,
  }),
  computed: {
    ...mapGetters({
      userPermissions: 'GET_USER_PERMISSIONS',
    }),
    columns() {
      return tableColumns.filter((col) => {
        if (this.userPermissions.allowMerchantSecretUid) return true;
        else {
          return col.name !== 'uid' && col.name !== 'secret';
        }
      });
    },
  },
  methods: {
    copyToClipboard,
    onSelection(data) {
      this.$emit('update:selected', data);
    },
  },
};
</script>
