<template>
  <d-view-card
    ref="view"
    :remove-function="merchantController.removeMerchant"
    :fetch-function="merchantController.getMerchants"
    remove-confirm-title="Are you sure you want to delete this merchant(s)?"
    remove-success-title="Merchant(s) successfully removed"
  >
    <template #default="{ selectedData, removeHandler, data }">
      <div class="col-grow">
        <div class="q-pa-md">
          <d-merchants-table
            :grid="$q.screen.lt.md"
            :items="data"
            :remove-fn="removeHandler"
            :selected.sync="$refs.view && $refs.view.$data.selectedData"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DMerchantsTable } from '@/features/merchants-table';
import DViewCard from '@/layouts/view-card';
import { merchantController } from '@/shared/api';

export default {
  components: { DViewCard, DMerchantsTable },
  data: () => ({
    merchantController,
  }),
};
</script>
