export const tableColumns = [
  {
    name: 'name',
    field: 'name',
    label: 'Name',
  },
  {
    name: 'payout-callback',
    field: 'payoutCallback',
    label: 'Payout Callback',
    style:
      'white-space: normal; max-width: 158px; overflow-wrap: break-word;',
  },
  {
    name: 'refill-callback',
    field: 'refillCallback',
    label: 'Refill Callback',
    style: 'white-space: normal; max-width: 158px; overflow-wrap: break-word;',
  },
  {
    name: 'uid',
    field: 'uid',
    label: 'UID',
  },
  {
    name: 'secret',
    field: 'secret',
    label: 'Secret',
  },
  {
    name: 'created-at',
    field: (row) => row?.createdAt?.date,
    label: 'Created at',
  },
  {
    name: 'updatedAt',
    field: (row) => row?.updatedAt?.date,
    label: 'Updated at',
  },
];